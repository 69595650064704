import axios from 'axios';
import * as CliftonAPI from "@/api-client";
export const axiosInstance = axios.create();

const globalConf = new CliftonAPI.Configuration({
  basePath: process.env.VUE_APP_ROOT_API,
});

const Authentication: CliftonAPI.IdentityAuthenticationApi = new CliftonAPI.IdentityAuthenticationApi(globalConf);
const Files: CliftonAPI.FilesApi = new CliftonAPI.FilesApi(globalConf);
const AdminDashboard: CliftonAPI.AdminDashboardApi = new CliftonAPI.AdminDashboardApi(globalConf);
const AdminCategories: CliftonAPI.AdminCategoriesApi = new CliftonAPI.AdminCategoriesApi(globalConf);
const AdminProductTags: CliftonAPI.AdminProductTagsApi = new CliftonAPI.AdminProductTagsApi(globalConf);
const AdminProducts: CliftonAPI.AdminProductsApi = new CliftonAPI.AdminProductsApi(globalConf);
const AdminExtras: CliftonAPI.AdminExtrasApi = new CliftonAPI.AdminExtrasApi(globalConf);
const AdminExtraGroups: CliftonAPI.AdminExtraGroupsApi = new CliftonAPI.AdminExtraGroupsApi(globalConf);
const AdminOverlays: CliftonAPI.AdminOverlaysApi = new CliftonAPI.AdminOverlaysApi(globalConf);
const AdminOverlayGroups: CliftonAPI.AdminOverlayGroupsApi = new CliftonAPI.AdminOverlayGroupsApi(globalConf);
const AdminColours: CliftonAPI.AdminColoursApi = new CliftonAPI.AdminColoursApi(globalConf);
const AdminColourGroups: CliftonAPI.AdminColourGroupsApi = new CliftonAPI.AdminColourGroupsApi(globalConf);
const AdminLayers: CliftonAPI.AdminLayersApi = new CliftonAPI.AdminLayersApi(globalConf);
const AdminStyles: CliftonAPI.AdminStylesApi = new CliftonAPI.AdminStylesApi(globalConf);
const AdminStyleGroups: CliftonAPI.AdminStyleGroupsApi = new CliftonAPI.AdminStyleGroupsApi(globalConf);
const AdminCustomizations: CliftonAPI.AdminCustomizationsApi = new CliftonAPI.AdminCustomizationsApi(globalConf);
const AdminCustomizationGroups: CliftonAPI.AdminCustomizationGroupsApi = new CliftonAPI.AdminCustomizationGroupsApi(globalConf);
const AdminCustomizationTypes: CliftonAPI.AdminCustomizationTypesApi = new CliftonAPI.AdminCustomizationTypesApi(globalConf);
const AdminDesigns: CliftonAPI.AdminDesignsApi = new CliftonAPI.AdminDesignsApi(globalConf);
const AdminDesignGroups: CliftonAPI.AdminDesignGroupsApi = new CliftonAPI.AdminDesignGroupsApi(globalConf);
const AdminArtAssets: CliftonAPI.AdminArtAssetsApi = new CliftonAPI.AdminArtAssetsApi(globalConf);
const AdminArtAssetCategories: CliftonAPI.AdminArtAssetCategoriesApi = new CliftonAPI.AdminArtAssetCategoriesApi(globalConf);
const AdminFonts: CliftonAPI.AdminFontsApi = new CliftonAPI.AdminFontsApi(globalConf);
const AdminFabrics: CliftonAPI.AdminFabricsApi = new CliftonAPI.AdminFabricsApi(globalConf);
const AdminFabricColours: CliftonAPI.AdminFabricColoursApi = new CliftonAPI.AdminFabricColoursApi(globalConf);
const AdminLayerFabrics: CliftonAPI.AdminLayerFabricsApi = new CliftonAPI.AdminLayerFabricsApi(globalConf);
const AdminBlogs: CliftonAPI.AdminBlogsApi = new CliftonAPI.AdminBlogsApi(globalConf);
const AdminBlogCategories: CliftonAPI.AdminBlogCategoriesApi = new CliftonAPI.AdminBlogCategoriesApi(globalConf);
const AdminGeneralSettings: CliftonAPI.AdminGeneralSettingsApi = new CliftonAPI.AdminGeneralSettingsApi(globalConf);
const GeneralSettings: CliftonAPI.GeneralSettingsApi = new CliftonAPI.GeneralSettingsApi(globalConf);
const AdminBannerItems: CliftonAPI.AdminBannerItemsApi = new CliftonAPI.AdminBannerItemsApi(globalConf);
const AdminProductBundles: CliftonAPI.AdminProductBundlesApi = new CliftonAPI.AdminProductBundlesApi(globalConf);
const AdminUsers: CliftonAPI.AdminUsersApi = new CliftonAPI.AdminUsersApi(globalConf);
const AdminRoles: CliftonAPI.AdminRolesApi = new CliftonAPI.AdminRolesApi(globalConf);
const AdminMedias: CliftonAPI.AdminMediasApi = new CliftonAPI.AdminMediasApi(globalConf);
const AdminClients: CliftonAPI.AdminClientsApi = new CliftonAPI.AdminClientsApi(globalConf);
const AdminProductPrices: CliftonAPI.AdminProductPricesApi = new CliftonAPI.AdminProductPricesApi(globalConf);
const AdminProductBundlePrices: CliftonAPI.AdminProductBundlePricesApi = new CliftonAPI.AdminProductBundlePricesApi(globalConf);
const AdminCustomizationPrices: CliftonAPI.AdminCustomizationPricesApi = new CliftonAPI.AdminCustomizationPricesApi(globalConf);
const AdminDesignPrices: CliftonAPI.AdminDesignPricesApi = new CliftonAPI.AdminDesignPricesApi(globalConf);
const AdminExtraPrices: CliftonAPI.AdminExtraPricesApi = new CliftonAPI.AdminExtraPricesApi(globalConf);
const AdminLayerPrices: CliftonAPI.AdminLayerPricesApi = new CliftonAPI.AdminLayerPricesApi(globalConf);
const AdminOverlayPrices: CliftonAPI.AdminOverlayPricesApi = new CliftonAPI.AdminOverlayPricesApi(globalConf);
const AdminStylePrices: CliftonAPI.AdminStylePricesApi = new CliftonAPI.AdminStylePricesApi(globalConf);
const AdminCurrencies: CliftonAPI.AdminCurrenciesApi = new CliftonAPI.AdminCurrenciesApi(globalConf);
const AdminCountries: CliftonAPI.AdminCountriesApi = new CliftonAPI.AdminCountriesApi(globalConf);
const AdminCountryGroups: CliftonAPI.AdminCountryGroupsApi = new CliftonAPI.AdminCountryGroupsApi(globalConf);
const AdminCountryImportDutyTypes: CliftonAPI.AdminCountryImportDutyTypesApi = new CliftonAPI.AdminCountryImportDutyTypesApi(globalConf);
const AdminAdjustments: CliftonAPI.AdminAdjustmentsApi = new CliftonAPI.AdminAdjustmentsApi(globalConf);
const AdminAdjustmentTypes: CliftonAPI.AdminAdjustmentTypesApi = new CliftonAPI.AdminAdjustmentTypesApi(globalConf);
const AdminSizes: CliftonAPI.AdminSizesApi = new CliftonAPI.AdminSizesApi(globalConf);
const AdminSizeGroups: CliftonAPI.AdminSizeGroupsApi = new CliftonAPI.AdminSizeGroupsApi(globalConf);
const AdminSizeGroupAttributes: CliftonAPI.AdminSizeGroupAttributesApi = new CliftonAPI.AdminSizeGroupAttributesApi(globalConf);
const AdminSizeGroupAttributeSizeValues: CliftonAPI.AdminSizeGroupAttributeSizeValuesApi = new CliftonAPI.AdminSizeGroupAttributeSizeValuesApi(globalConf);
const AdminStyleSizeGroups: CliftonAPI.AdminStyleSizeGroupsApi = new CliftonAPI.AdminStyleSizeGroupsApi(globalConf);
const AdminStyleAdjustmentTypes: CliftonAPI.AdminStyleAdjustmentTypesApi = new CliftonAPI.AdminStyleAdjustmentTypesApi(globalConf);
const AdminStyleSizeInconsistenciesApi: CliftonAPI.AdminStyleSizeInconsistenciesApi = new CliftonAPI.AdminStyleSizeInconsistenciesApi(globalConf);
const AdminDeliveryTypes: CliftonAPI.AdminDeliveryTypesApi = new CliftonAPI.AdminDeliveryTypesApi(globalConf);
const AdminCountryDeliveryTypes: CliftonAPI.AdminCountryDeliveryTypesApi = new CliftonAPI.AdminCountryDeliveryTypesApi(globalConf);
const AdminProductionSpeeds: CliftonAPI.AdminProductionSpeedsApi = new CliftonAPI.AdminProductionSpeedsApi(globalConf);
const AdminProductProductionSpeeds: CliftonAPI.AdminProductProductionSpeedsApi = new CliftonAPI.AdminProductProductionSpeedsApi(globalConf);
const AdminColourPrices: CliftonAPI.AdminColourPricesApi = new CliftonAPI.AdminColourPricesApi(globalConf);
const AdminOrders: CliftonAPI.AdminOrdersApi = new CliftonAPI.AdminOrdersApi(globalConf);
const AdminOrderItemAssets: CliftonAPI.AdminOrderItemAssetsApi = new CliftonAPI.AdminOrderItemAssetsApi(globalConf);
const AdminOrderCosts: CliftonAPI.AdminOrderCostsApi = new CliftonAPI.AdminOrderCostsApi(globalConf);
const AdminOrderPayments: CliftonAPI.AdminOrderPaymentsApi = new CliftonAPI.AdminOrderPaymentsApi(globalConf);
const AdminOrderPacks: CliftonAPI.AdminOrderPacksApi = new CliftonAPI.AdminOrderPacksApi(globalConf);
const AdminCouriers: CliftonAPI.AdminCouriersApi = new CliftonAPI.AdminCouriersApi(globalConf);
const CustomizedProducts: CliftonAPI.CustomizedProductsApi = new CliftonAPI.CustomizedProductsApi(globalConf);
const AdminTasks: CliftonAPI.AdminTasksApi = new CliftonAPI.AdminTasksApi(globalConf);
const AdminTaskMessages: CliftonAPI.AdminTaskMessagesApi = new CliftonAPI.AdminTaskMessagesApi(globalConf);
const PublicOrders: CliftonAPI.OrdersApi = new CliftonAPI.OrdersApi(globalConf);
const AdminEnquiries: CliftonAPI.AdminEnquiriesApi = new CliftonAPI.AdminEnquiriesApi(globalConf);
const AdminOrderAuditLogs: CliftonAPI.AdminOrderAuditLogsApi = new CliftonAPI.AdminOrderAuditLogsApi(globalConf);
const PublicCountries: CliftonAPI.CountriesApi = new CliftonAPI.CountriesApi(globalConf);
const AdminFactories: CliftonAPI.AdminFactoriesApi = new CliftonAPI.AdminFactoriesApi(globalConf);
const AdminOrderInvoices: CliftonAPI.AdminOrderInvoicesApi = new CliftonAPI.AdminOrderInvoicesApi(globalConf);
const AdminVouchers: CliftonAPI.AdminVouchersApi = new CliftonAPI.AdminVouchersApi(globalConf);
const AdminSEO: CliftonAPI.AdminPageSeoSettingsApi = new CliftonAPI.AdminPageSeoSettingsApi(globalConf);
const AdminFabricChannels: CliftonAPI.AdminFabricChannelsApi = new CliftonAPI.AdminFabricChannelsApi(globalConf);
const AdminProductBuilder: CliftonAPI.AdminProductBuilderApi = new CliftonAPI.AdminProductBuilderApi(globalConf);
const AdminProductCategoryMenuGroups: CliftonAPI.AdminProductCategoryMenuGroupsApi = new CliftonAPI.AdminProductCategoryMenuGroupsApi(globalConf);
const AdminStores: CliftonAPI.AdminStoresApi = new CliftonAPI.AdminStoresApi(globalConf);
const AdminStoreCustomizedProducts: CliftonAPI.AdminStoreCustomizedProductsApi = new CliftonAPI.AdminStoreCustomizedProductsApi(globalConf);
const AdminBannerMessages: CliftonAPI.AdminBannerMessagesApi = new CliftonAPI.AdminBannerMessagesApi(globalConf);
const AdminExtraCustomizedProductItemTemplate: CliftonAPI.AdminExtraCustomizedProductItemTemplateApi = new CliftonAPI.AdminExtraCustomizedProductItemTemplateApi(globalConf);
const AdminCarouselItems: CliftonAPI.AdminCarouselItemsApi = new CliftonAPI.AdminCarouselItemsApi(globalConf);
const PublicCarts: CliftonAPI.CartsApi = new CliftonAPI.CartsApi(globalConf);
const AdminCampaigns: CliftonAPI.AdminCampaignsApi = new CliftonAPI.AdminCampaignsApi(globalConf);

export { AdminDashboard, AdminProductPrices, Authentication, Files, AdminCategories, AdminProductTags, AdminProducts, AdminExtras, AdminExtraGroups, AdminOverlays, AdminOverlayGroups, AdminColours, AdminColourGroups, AdminLayers, AdminStyles, AdminStyleGroups, AdminCustomizations, AdminCustomizationGroups, AdminCustomizationTypes, AdminDesigns, AdminDesignGroups, AdminArtAssets, AdminArtAssetCategories, AdminFonts, AdminFabrics, AdminFabricColours, AdminLayerFabrics, AdminBlogs, AdminBlogCategories, AdminGeneralSettings, AdminBannerItems, GeneralSettings, AdminProductBundles, AdminUsers, AdminRoles, AdminMedias, AdminClients, AdminProductBundlePrices, AdminCustomizationPrices, AdminDesignPrices, AdminExtraPrices, AdminLayerPrices, AdminOverlayPrices, AdminStylePrices, AdminCurrencies, AdminCountries, AdminCountryGroups, AdminCountryImportDutyTypes, AdminAdjustments, AdminAdjustmentTypes, AdminSizes, AdminSizeGroups, AdminSizeGroupAttributes, AdminSizeGroupAttributeSizeValues, AdminStyleSizeGroups, AdminStyleAdjustmentTypes, AdminStyleSizeInconsistenciesApi, AdminDeliveryTypes, AdminCountryDeliveryTypes, AdminProductionSpeeds, AdminProductProductionSpeeds, AdminColourPrices, AdminOrderItemAssets, AdminOrders, AdminOrderCosts, CustomizedProducts, AdminOrderPayments, AdminOrderPacks, AdminCouriers, AdminTasks, AdminTaskMessages, PublicOrders, AdminEnquiries, AdminOrderAuditLogs, PublicCountries, AdminFactories, AdminOrderInvoices, AdminVouchers, AdminSEO, AdminFabricChannels, AdminProductBuilder, AdminProductCategoryMenuGroups, AdminStores, AdminStoreCustomizedProducts, AdminBannerMessages, AdminExtraCustomizedProductItemTemplate, AdminCarouselItems, PublicCarts, AdminCampaigns };
