
























































import path from "path";
import { Component, Prop, Vue } from "vue-property-decorator";
import { RouteConfig } from "vue-router";
import { isExternal } from "@/utils/validate";
import SidebarItemLink from "./SidebarItemLink.vue";
import store from "@/store";
import { type } from "os";

@Component({
  // Set 'name' here to prevent uglifyjs from causing recursive component not work
  // See https://medium.com/haiiro-io/element-component-name-with-vue-class-component-f3b435656561 for detail
  name: "SidebarItem",
  components: {
    SidebarItemLink,
  },
})
export default class extends Vue {
  @Prop({ required: true }) private item!: RouteConfig;
  @Prop({ default: false }) private isCollapse!: boolean;
  @Prop({ default: true }) private isFirstLevel!: boolean;
  @Prop({ default: "" }) private basePath!: string;

  usersPermissions: Array<string> = [];

  get theOnlyOneChild() {
    if (this.showingChildNumber > 1) {
      return null;
    }
    if (this.item.children) {
      for (const child of this.item.children) {
        if (!child.meta || !child.meta.hidden) {
          return child;
        }
      }
    }
    // If there is no children, return itself with path removed,
    // because this.basePath already conatins item's path information
    return { ...this.item, path: "" };
  }

  get showingChildNumber() {
    if (this.item.children) {
      const showingChildren = this.item.children.filter((item) => {
        if (item.meta && item.meta.hidden) {
          return false;
        } else {
          return true;
        }
      });
      return showingChildren.length;
    }
    return 0;
  }

  get roles() {
    return store.getters["user_new/role"] as any;
  }

  created() {
    this.usersPermissions = this.getUserPermissions() as Array<string> || [];
  }

  getUserPermissions() {
    if (this.roles && this.roles.length) {
      let allRoutes = (this.$router as any).matcher.getRoutes();
      let parentRoutes = [] as Array<string>;

      // Get names of all parent routes
      allRoutes.forEach((route: any) => {
        //if (route.parent) {
          if(route.parent) {
            parentRoutes.push(route.parent.meta?.title || route.parent.name);
          }

          if (route.meta?.title) {
            parentRoutes.push(route.meta.title as string);
          } else if (route.name) {
            parentRoutes.push(route.name as string);
          }
      });

      //Admin - everything
      //Has access to everything, no further logic necessary
      if (this.roles.includes("Admin")) {
        return parentRoutes;
      } else {
        let permissions = {
          // salesadmin, sales, operations: Show things they have permission to see
          "sales admin": ["Orders", "Dashboard", "Enquiries", "Task Overview", "Online Stores", "Campaigns"],
          sales: ["Orders", "Dashboard", "Enquiries", "Task Overview", "Online Stores"],
          operations: ["Orders", "Factories", "Enquiries", "Dashboard", "Task Overview", "Online Stores"],
          "marketing & design": parentRoutes.filter(function (val) {
            // marketing & design: Hide things they don't have permission to see
            return [
              "Orders",
              "Enquiries",
              "Dashboard",
              "Task Overview",
              "Factories",
              "Users",
              "Online Stores"
            ].indexOf(val) == -1
              ? true
              : false;
          }),
        };

        // Get all permissions of current user based on role/s
        let permissionArray = [];
        if (typeof this.roles !== "string" && this.roles.length) {
          this.roles.forEach((role: string) => {
            permissionArray = permissionArray.concat(
              permissions[role.toLowerCase()]
            );
          });
          
        } else if (typeof this.roles === "string") {
          permissionArray = permissions[this.roles.toLowerCase()];
        }

        //remove duplicates
        permissionArray = permissionArray.filter(
            (item, pos) => permissionArray.indexOf(item) === pos
          );

        return permissionArray;
      }
    }
  }

  showOnFull(item: any) {
    let title = item?.meta?.title;

    if (!this.isCollapse) {
      if (["Dashboard", "Task Overview"].includes(title)) {
        return false;
      }
    } else {
      return this.showByRole(title);
    }
    return true;
  }

  showByRole(item: any) {
    if(this.roles.includes("Admin")) {
      return true;
    }

    if(this.usersPermissions.length) {
      let title: any;

      if (this.roles && this.roles.length) {
        (typeof item === "string") ? (title = item) : (title = item?.meta?.title)

        // Return true if current user has access to this item
        return (title && this.usersPermissions.includes(title)) ? true : false;
      }
    }
    return false
  }

  private resolvePath(routePath: string) {
    if (isExternal(routePath)) {
      return routePath;
    }
    if (isExternal(this.basePath)) {
      return this.basePath;
    }
    return path.resolve(this.basePath, routePath);
  }
}
